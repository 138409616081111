/* src/index.css */
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

section {
  padding: 100px 20px;
  min-height: 100vh;
}

.hero-section {
  background-color: #282c34;
  color: white;
  text-align: center;
}

.about-section, .projects-section, .contact-section {
  background-color: #f9f9f9;
  text-align: center;
  color: black;
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}
