/* Add this to your global CSS file or individual component CSS */
section {
    padding-top: 60px; /* Adjust according to navbar height */
    margin-top: -60px; /* This ensures smooth scroll */
  }
  /* Social Icons */
  .social-icons img {
    width: 40px;  /* Adjust the size as needed */
    margin: 0 10px;  /* Spacing between icons */
  }
  